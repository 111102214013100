<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        <label class="btn btn-primary font-scale-2 font-medium">
          {{ page_title }}
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars/selling')"
               title="Продавцы">
          Продавцы
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/cars/calendar')"
               title="Календарь">
          Календарь
        </label>

        <router-link :to="{ name: 'car.create' }" class="btn btn-success pull-right"
                     title="Добавить" type="button">
          <i class="fa fa-plus"></i>
        </router-link>
      </h3>

      <div class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_cars.show_only = 'my';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_cars.show_only = 'all';
                 updateFilters();
               }" autocomplete="off" :checked="filter_cars.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_cars.branch_id = branch ? branch.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <div v-if="filter_cars.show_only !== 'my'" class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_cars.responsible_id = responsible ? responsible.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="marks"
                           :searchable="true"
                           id="filter-by-mark"
                           @input="() => {
                             filter_cars.mark_id = mark ? mark.id : null;
                             updateFilters();
                           }"
                           @select="getCarModels"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Марка"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Марка"
                           track-by="id"
                           v-model="mark">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :disabled="!models || models.length === 0"
                           :options="models"
                           :searchable="true"
                           id="filter-by-model"
                           @input="() => {
                             filter_cars.model_id = model ? model.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Модель"
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Модель"
                           track-by="id"
                           v-model="model">
                <template slot="placeholder">
                  <i class="fas font-scale fa-circle-notch fa-spin text-blue" v-if="onProgressCarModels === true" />
                  Модель
                </template>
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="year_min"
                     name="year_min"
                     placeholder="Год, с"
                     title="Год, с"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.year_min">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="year_max"
                     name="year_max"
                     placeholder="Год, по"
                     title="Год, по"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.year_max">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="price_min"
                     name="price_min"
                     placeholder="Цена, от"
                     title="Цена, от"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.price_min">
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="price_max"
                     name="price_max"
                     placeholder="Цена, по"
                     title="Цена, по"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.price_max">
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="types"
                           :searchable="true"
                           id="filter-by-types"
                           @input="() => {
                             filter_cars.type_id = type ? type.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Тип кузова"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Тип кузова"
                           track-by="id"
                           v-model="type">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="gearboxes"
                           :searchable="true"
                           id="filter-by-gearbox"
                           @input="() => {
                             filter_cars.gearbox_id = gearbox ? gearbox.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="КПП"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="КПП"
                           track-by="id"
                           v-model="gearbox">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="fuels"
                           :searchable="true"
                           id="filter-by-fuel"
                           @input="() => {
                             filter_cars.fuel_id = fuel ? fuel.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Топливо"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Топливо"
                           track-by="id"
                           v-model="fuel">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-1 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="transmissions"
                           :searchable="false"
                           id="filter-by-transmission"
                           @input="() => {
                             filter_cars.transmission_id = transmission ? transmission.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Привод"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Привод"
                           track-by="id"
                           v-model="transmission">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="origins"
                           :searchable="true"
                           id="filter-by-origin"
                           @input="() => {
                             filter_cars.origin_id = origin ? origin.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Происхождение"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Происхождение"
                           track-by="id"
                           v-model="origin">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <input class="pointer margin-l-5"
                     style="transform: scale(1.2);"
                     id="filter-by-only-disabled"
                     type="checkbox"
                     @input="() => {
                       filter_cars.only_disabled = !filter_cars.only_disabled;
                       filter_cars.without_disabled = false;
                       updateFilters();
                     }"
                     v-model="filter_cars.only_disabled">
              <label for="filter-by-only-disabled" class="margin-l-5 padding-l-10">
                Только скрытые
              </label>

              <br />

              <input class="pointer margin-l-5"
                     style="transform: scale(1.2);"
                     id="filter-by-not-disabled"
                     type="checkbox"
                     @input="() => {
                       filter_cars.only_disabled = false;
                       filter_cars.without_disabled = !filter_cars.without_disabled;
                       updateFilters();
                     }"
                     v-model="filter_cars.without_disabled">
              <label for="filter-by-not-disabled" class="margin-l-5 padding-l-10">
                Только опубликованные
              </label>
            </div>

            <div class="col-xl-3 col-md-6 col-sm-12 mb-1">
              <input class="pointer margin-l-5"
                     style="transform: scale(1.2);"
                     id="filter-by-only-company-car"
                     type="checkbox"
                     @input="() => {
                       filter_cars.without_owner_cars = !filter_cars.without_owner_cars;
                       filter_cars.only_owner_cars = false;
                       updateFilters();
                     }"
                     v-model="filter_cars.without_owner_cars">
              <label for="filter-by-only-company-car" class="margin-l-5 padding-l-10">
                Только авто компании
              </label>

              <br />

              <input class="pointer margin-l-5"
                     style="transform: scale(1.2);"
                     id="filter-by-only-client-car"
                     type="checkbox"
                     @input="() => {
                       filter_cars.without_owner_cars = false;
                       filter_cars.only_owner_cars = !filter_cars.only_owner_cars;
                       updateFilters();
                     }"
                     v-model="filter_cars.only_owner_cars">
              <label for="filter-by-only-client-car" class="margin-l-5 padding-l-10">
                Только авто клиентов
              </label>
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="race_min"
                     name="race_min"
                     placeholder="Пробег, от"
                     title="Пробег, от"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.race_min">
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <input class="form-control"
                     id="race_max"
                     name="race_max"
                     placeholder="Пробег, до"
                     title="Пробег, до"
                     type="number"
                     @input="updateFilters();"
                     v-model="filter_cars.race_max">
            </div>

            <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
              <multiselect :allow-empty="true"
                           :options="contract_types"
                           :searchable="true"
                           id="filter-by-contract_types"
                           @input="() => {
                             filter_cars.contract_type = contract_type ? contract_type.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Тип договора"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Тип договора"
                           track-by="id"
                           v-model="contract_type">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </div>


          </div>
        </div>
      </transition>

      <card-items :data_search="data_search"
                  :filters_query="filter_cars"
                  :relations="relations"
                  component="car-item"
                  item_classes="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 cars-grid-item"
                  api_resource="/cars">
      </card-items>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BranchService from "@/services/BranchService";
import Multiselect from "vue-multiselect";
import CardItems from "./../../components/pagination/card-items";

Vue.component("car-item", (resolve) => {
  return require(["./item"], resolve);
});

export default {
  name: "cars",

  components: {
    Multiselect,
    CardItems,
  },

  props: {
    data_search: {
      type: String,
      required: false,
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Автомобили",

    branches: [],
    branch: null,
    employees: [],
    responsible: null,
    marks: [],
    mark: null,
    models: [],
    model: [],
    origins: [],
    origin: null,
    types: [],
    type: null,
    gearboxes: [],
    gearbox: null,
    fuels: [],
    fuel: null,
    transmissions: [],
    transmission: null,
    contract_types: [
      {
        id: 'free',
        title: 'Бесплатный',
      },
      {
        id: 'compensation',
        title: 'Компенсация',
      }
    ],
    contract_type: null,

    relations: [
      "branch",
      "equipments",
      "specification.gearbox",
      "specification.fuel",
      "specification.color",
      "specification.transmission",
      "origin",
      "sold",
      "seo",
      "creator",
      "editor",
    ],

    filter_cars: {
      show_only: null,
      branch_id: null,
      responsible_id: null,
      mark_id: null,
      model_id: null,
      origin_id: null,
      type_id: null,
      year_min: null,
      year_max: null,
      price_min: null,
      price_max: null,
      race_min: null,
      race_max: null,
      gearbox_id: null,
      fuel_id: null,
      transmission_id: null,
      owner_type: null,
      only_disabled: null,
      without_disabled: null,
      without_owner_cars: null,
      only_owner_cars: null,
    },

    onProgressCarModels: false,
  }),

  async created() {
    try {
      if ([1, 4, 5, 6, 7, 8, 9].includes(this.$store.getters["auth/authUser"].role.id)) {
        this.filter_cars.show_only = "all";
      } else {
        this.filter_cars.show_only = "my";
      }

      const [
        employees_response,
        car_marks,
        origins_response,
        types_response,
        gearboxes_response,
        fuels_response,
        transmissions_response
      ] = await axios.all([
        API.apiClient.get("/employees", {
          params: this.$apiRequestParams.filters_list,
        }),
        API.apiClient.get("/cars/marks?available=1", {
          params: {
            is_paginated: 0,
            sorting: "asc",
            sort_column: "name",
            filters_query: {
              only_available: true,
            },
          },
        }),
        API.apiClient.get("/cars/origins"),
        API.apiClient.get("/cars/types"),
        API.apiClient.get("/cars/gearboxes"),
        API.apiClient.get("/cars/fuels"),
        API.apiClient.get("/cars/transmissions")
      ]);

      this.branches = await BranchService.getItems();
      this.employees = employees_response.data;
      this.marks = car_marks.data;
      this.origins = origins_response.data;
      this.types = types_response.data;
      this.gearboxes = gearboxes_response.data;
      this.fuels = fuels_response.data;
      this.transmissions = transmissions_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.filter_cars.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models/available",
          {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    updateFilters() {
      this.EventBus.$emit("card-filter-changed");
    },
  },
};
</script>

<style scoped>

</style>
